import React, { useState, useEffect, useContext } from "react";
import Link from "../../components/custom/Link";
import { ThemeContext } from "../../contexts/ThemeContext";
import { setImageSrc } from "../../utils/config";
import CustomImage from "../../components/custom/CustomImage";
import Image from "next/image";
import axios from "axios";
import { BUILDER_URL} from "../../utils/config";

const Collage = ({ widgetId,onImageLoad  }) => {

  const BASE_URL = BUILDER_URL;

  const theme = useContext(ThemeContext);
  const [collageData, setCollageData] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { collage_structure, collage_image, widget_title } = collageData || {};
  // const [loading, setLoading] = useState(true);

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowWidth(window.innerWidth);
    }, 300);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (widgetId) {
      axios
        .get(`${BASE_URL}/collage/${widgetId}`, {
          headers: {
            Authorization: `Bearer ${theme.token}`,
          },
        })
        .then((response) => {
          // setLoading(false);
          if (response.data && response.data.length > 0) {
            setCollageData(response.data[0]);
          }
        })
        .catch((error) => {
          // setLoading(false);
          console.error("Error fetching collage data:", error);
          setCollageData(null);
        });
    }

   
  }, [widgetId]);

  // if (!collageData) {
  //   return (
  //     <div className="shimmer space-y-4">
  //       <div className="h-64 bg-gray-300 rounded-md"></div>
  //     </div>
  //   );
  // }

  return (
    <>
      {collageData?.collage_structure !== null ? (
        <div className="mb-2">
          <h1 className="text-xl lg:text-2xl px-3 lg:px-0 lg:mb-4 font-semibold text-primary text-center flex justify-center">
            {widget_title}
          </h1>

          {collage_structure === 0 && (
            <div className={`lg:mb-4 ${collageData?.is_box_width === 1 ? "block xl:container px-4" : " flex justify-center items-center lg:block"}`}>
              <Link href={collage_image[0]?.image_link}>
                <img
                  src={setImageSrc(collage_image[0]?.image_url, "")}
                  alt={"Single Collage"}
                  placeholder="empty"
                  className="rounted-md shimmer"
                  width={1920}
                  height={1080}
                />

                {/* <img
                  className="rounded-md"
                  src={setImageSrc(collage_image[0]?.image_url, "")}
                  alt="Collage 1"
                  width={1920} // Set the desired width for the banner image
                  height={1080} // Set the desired height for the banner image
                  priority={true} // Preload critical image
                /> */}
              </Link>
            </div>
          )}

          {collage_structure === 1 && (
            <div
              className={`mt-4 ${
                collageData?.is_box_width === 1
                  ? "block xl:container px-8"
                  : "block xl:container"
              }`}
            >
              <div className="grid md:grid-cols-12 sm:grid-cols-1 gap-2">
                <div className="md:col-span-7">
                  <Link href={collage_image[0]?.image_link}>
                    <img
                      src={setImageSrc(collage_image[0]?.image_url, "")}
                      alt="collage 1"
                      width={700}
                      height={900}
                      className="shimmer w-full h-full"
                      placeholder="empty"
                      onError={() => {
                        console.log("error");
                      }}
                    />
                    {/* <img
                      className="md:rounded-md object-cover w-full h-full"
                      src={setImageSrc(collage_image[0]?.image_url, "")}
                      alt="Collage"
                      width={700} // Adjust the width as necessary
                      height={900} // Adjust the height as necessary
                      priority={true} // Preload critical image
                    /> */}
                  </Link>
                </div>
                <div className="md:col-span-5 flex flex-col gap-2">
                  {collage_image.slice(1, 4).map((imageData, index) => (
                    <div key={index}>
                      <Link
                        href={imageData.image_link}
                        className="cursor-pointer"
                      >
                        <img
                          src={setImageSrc(imageData.image_url, "")}
                          className="md:rounded-md shimmer object-cover w-full h-full"
                          alt={`collage item ${index + 1}`}
                          width={500}
                          placeholder="empty"
                          onError={() => {
                            console.log("error");
                          }}
                          height={300}
                        />
                        {/* <img
                          className="md:rounded-md shimmer object-cover w-full h-full"
                          src={setImageSrc(imageData.image_url, "")}
                          alt={`Collage item ${index + 1}`}
                          width={500} // Adjust the width as necessary
                          height={300} // Adjust the height as necessary
                        /> */}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {collage_structure === 2 && (
            <div
              className={`mt-4 ${
                collageData?.is_box_width === 1
                  ? "block xl:container px-8"
                  : "block xl:container"
              }`}
            >
              <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-2">
                <div>
                  <Link href={collage_image[0]?.image_link}>
                    <img
                      className="md:rounded-md object-cover w-full h-full"
                      src={setImageSrc(collage_image[0]?.image_url, "")}
                      alt="Collage"
                      width={600} // Adjust the width as necessary
                      height={900} // Adjust the height as necessary
                      priority={true} // Preload critical image
                    />
                  </Link>
                </div>
                <div>
                  <div className="grid grid-cols-2 gap-2">
                    {collage_image.slice(1, 5).map((imageData, index) => (
                      <div key={index}>
                        <Link
                          href={imageData.image_link}
                          className="cursor-pointer"
                        >
                          <img
                            className="md:rounded-md object-cover w-full h-full"
                            src={setImageSrc(imageData.image_url, "")}
                            alt={`Secondary Collage Image ${index + 1}`}
                            width={300} // Adjust the width as necessary
                            height={300} // Adjust the height as necessary
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {collage_structure === 3 && (
            <div
              className={`mt-4 ${
                collageData?.is_box_width === 1
                  ? "block xl:container px-8"
                  : "block xl:container"
              }`}
            >
              <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-2">
                {collage_image.slice(0, 3).map((imgObj, index) => (
                  <div key={index}>
                    <Link href={imgObj.image_link}>
                      <img
                        className="md:rounded-md pr-1 object-cover h-full w-full"
                        src={setImageSrc(imgObj.image_url, "")}
                        alt={`Collage item ${index + 1}`}
                        width={400} // Adjust the width as necessary
                        height={600} // Adjust the height as necessary
                      />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )}

{/* Multi Collage Structure render- 14-08-2024 */}
{collage_structure === 4 && (
   <div
   className={`mt-4 ${
     collageData?.is_box_width === 1
       ? "block xl:container px-8"
       : "block xl:container"
   }`}
 >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div className=" h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className="">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className="">
              <img className="image w-full h-full" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
            <div className="sm:col-span-2 md:col-span-2  h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[3]?.image_url)} alt="" />
            </div>
            <div className="">
              <img className="image w-full h-full" src={setImageSrc(collage_image[4]?.image_url)} alt="" />
            </div>
            <div className="">
              <img className="image w-full h-full" src={setImageSrc(collage_image[5]?.image_url)} alt="" />
            </div>
            <div className="sm:col-span-2 md:col-span-2  h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[6]?.image_url)} alt="" />
            </div>
          </div>
  </div>
        )}
{/* fixed */}
        {collage_structure === 5 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div className="w-full h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className="w-full h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className="w-full h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}

        {collage_structure === 6 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <div className=" h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[3]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}

        {collage_structure === 7 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            <div className=" h-full sm:h-full">
              <img className="w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full">
              <img className="w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full">
              <img className="w-full h-full" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full">
              <img className="w-full h-full" src={setImageSrc(collage_image[3]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full">
              <img className="w-full h-full" src={setImageSrc(collage_image[4]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}

        {collage_structure === 8 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div className=" col-span-1 sm:col-span-2 md:col-span-2 h-full">
              <img className="w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className=" col-span-1 h-full">
              <img className="w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}

        {collage_structure === 9 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-12 gap-4">
            <div className="col-span-1 sm:col-span-1 md:col-span-3  h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className="col-span-1 sm:col-span-1 md:col-span-6  h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className="col-span-1 sm:col-span-1 md:col-span-3  h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}
{/* fixed */}
        {collage_structure === 10 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-12 gap-4">
            
            <div className="col-span-1 sm:col-span-2 md:col-span-6  h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className="col-span-1 sm:col-span-1 md:col-span-3  h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className="col-span-1 sm:col-span-1 md:col-span-3  h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}

        {collage_structure === 11 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-12 gap-4">
            <div className="col-span-1 sm:col-span-1 md:col-span-3  h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className="col-span-1 sm:col-span-1 md:col-span-3  h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className="col-span-1 sm:col-span-2 md:col-span-6  h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}
{/* image order fix */}
        {collage_structure === 12 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className=" row-span-1 md:row-span-2 h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[3]?.image_url)} alt="" />
            </div>
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[4]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}
{/* Image order fix */}
        {collage_structure === 13 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
            <div className=" row-span-1 sm:row-span-2 md:row-span-2 h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[4]?.image_url)} alt="" />
            </div>
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[3]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}
{/* Image order fix */}
        {collage_structure === 14 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div className=" md:row-span-2 h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[3]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[4]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}
{/* Image order fix */}
        {collage_structure === 15 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div className=" h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[4]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[3]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[5]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}

        {collage_structure === 16 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className=" sm:row-span-2 h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}

        {collage_structure === 17 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className="sm:row-span-2 h-full sm:h-full">
              <img className="image w-full h-full object-fit" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
            <div className="h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}

        {collage_structure === 18 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className=" h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}

        {collage_structure === 19 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-2 sm:grid-cols-2 gap-4">
            <div className="w-full h-full sm:row-span-2 sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className=" w-full h-full sm:row-span-2 sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className="w-full h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
            <div className="w-full h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[3]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}

        {collage_structure === 20 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className=" md:row-span-2 h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className=" md:row-span-2 h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[3]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}

        {collage_structure === 21 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className=" h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className=" sm:row-span-2 h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className=" sm:row-span-2 h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
            <div className=" h-full sm:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[3]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}
{/* Image order fix */}
        {collage_structure === 22 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className=" md:row-span-2 h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className=" md:row-span-2 h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[3]?.image_url)} alt="" />
            </div>
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}

        {collage_structure === 23 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className=" md:row-span-1 h-full md:h-full">
              <img className="image w-full h-full " src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[3]?.image_url)} alt="" />
            </div>

            <div className=" md:col-span-2 h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[4]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}

        {collage_structure === 24 && (
            <div
            className={`mt-4 ${
              collageData?.is_box_width === 1
                ? "block xl:container px-8"
                : "block xl:container"
            }`}
          >
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className=" md:col-span-2 h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[0]?.image_url)} alt="" />
            </div>
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[1]?.image_url)} alt="" />
            </div>
            <div className=" md:row-span-1 h-full md:h-full">
              <img className="image  w-full h-full " src={setImageSrc(collage_image[2]?.image_url)} alt="" />
            </div>
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[3]?.image_url)} alt="" />
            </div>
            <div className=" h-full md:h-full">
              <img className="image w-full h-full" src={setImageSrc(collage_image[4]?.image_url)} alt="" />
            </div>
          </div>
            </div>
        )}


        </div>
      ) : (
        false
      )}
    </>
  );
};

export default Collage;
